import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  RelationshipField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Comment extends CrudModel {
  protected static _typeLabel = "Comment";
  protected static asProperty = "comment";
  public static api = {
    path: "comments",
  };
  protected static routeBase = "comments";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );
  protected userIdField = ["user"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "comment",
        label: "Comment",
        rules: ["required"],
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.New
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        label: "Contact",
        relatedModel: "Contact",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.New
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "invoice",
        label: "Invoice",
        relatedModel: "Invoice",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.New
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "account",
        label: "Account",
        relatedModel: "Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.New
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.New
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
        computed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: LongTextField, opts: { property: "comment" } },
    { type: RelationshipField, opts: { property: "contact" } },
    { type: RelationshipField, opts: { property: "invoice" } },
    { type: RelationshipField, opts: { property: "account" } },
    { type: RelationshipField, opts: { property: "contract" } },
    { type: RelationshipField, opts: { property: "user" } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["created_at", "comment", "user"],
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
    {
      id: "asRelationship",
      columnFields: ["created_at", "comment", "user"],
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      headerComponents: ["ContractPDFButton"],
      fieldSets: [
        {
          id: "1",
          md: 4,
          xl: 3,
          fields: ["contact", "invoice", "account", "contract"],
        },
        {
          id: "0",
          md: 8,
          xl: 6,
          fields: ["comment", "created_at"],
        },
      ],
    },
  ];
}

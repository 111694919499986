import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { UserPermission } from "../plugins/Cruxtify/UserPermission";
import {
  LongTextField,
  NumberField,
  StringField,
  WysiwygField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class NotificationType extends CrudModel {
  protected static _typeLabel = "Notification Type";
  protected static asProperty = "notification_type";
  public static api = {
    path: "notification-types",
  };
  protected static routeBase = "notification-types";
  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "slug",
        label: "Slug",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "label",
        label: "Label",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "note",
        label: "Note",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "subject",
        label: "Subject",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "text_body",
        label: "Text Body",
        rules: ["required"],
        description:
          "This is the message that will be sent via text for this notification type.",
        // maxLength: 160
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "email_body",
        label: "Email Body",
        description:
          "This is the message that will be sent via email for this notification type. If left blank, the text body will be used.",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "delay",
        label: "Notification Delay",
        description:
          "When the notification is triggered, it will be delayed by this many minutes.",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: StringField,
      opts: { property: "label", isPrimaryLabelField: true },
    },
    {
      type: NumberField,
      opts: { property: "delay" },
    },
    { type: LongTextField, opts: { property: "note", label: "Description" } },
    { type: StringField, opts: { property: "subject" } },
    { type: LongTextField, opts: { property: "text_body" } },
    { type: WysiwygField, opts: { property: "email_body" } },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
    },
  ];
}

import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  BooleanField,
  DateTimeField,
  LongTextField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Call extends CrudModel {
  protected static _typeLabel = "Call";
  protected static asProperty = "call";
  public static api = {
    path: "calls",
  };
  protected static routeBase = "calls";
  protected userIdField = ["users"];
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "call_time",
        label: "Call Time",
        rules: ["required"],
        default: DateTimeProperty.now,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "please_call",
        label: "Please Call",
        default: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Logged By",
        relatedModel: "User",
        userPermissions: new UserPermissions(UserPermission.Read),
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        label: "Contact",
        relatedModel: "Contact",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "users",
        label: "Owner(s)",
        relatedModel: "User",
        foreignProperty: "calls",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
        rules: ["required"],
        // reactiveValue: {
        //   value: (prop: CrudProperty) => {
        //     if (!prop.isEmpty) return undefined;

        //     const contact = prop.model?.getProperty("contact").typedValue;
        //     if (!contact) return undefined;

        //     const model = contact.getProperty("user").typedValue;
        //     return !model.isNew ? [model.id] : undefined;
        //   }
        // }
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "call_time" } },
    {
      type: LongTextField,
      opts: { property: "description", fieldWidth: "full" },
    },
    { type: BooleanField, opts: { property: "please_call" } },
    { type: RelationshipField, opts: { property: "author" } },
    {
      type: RelationshipField,
      opts: { property: "contact", rules: ["required"] },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "users",
        fieldWidth: "normal",
      },
    },
    { type: RelationshipField, opts: { property: "last_mod_author" } },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          md: 6,
          fields: ["call_time", "contact", "users", "please_call"],
        },
        {
          id: "3",
          md: 6,
          fields: ["description"],
        },
        {
          id: "meta",
          fields: ["created_at", "updated_at", "author", "last_mod_author"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "call_time",
        "contact",
        "description",
        "please_call",
        "users",
        "author",
      ],
      useModal: true,
      filterFields: ["users"],
      defaultSort: [
        {
          field: "call_time",
          order: "desc",
        },
      ],
    },

    {
      id: "asRelationship",
      columnFields: [
        "call_time",
        "contact",
        "description",
        "please_call",
        "users",
        "author",
      ],
      defaultSort: [
        {
          field: "call_time",
          order: "desc",
        },
      ],
    },
  ];
}

import { AddressFilter } from "../filters/AddressFilter";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  BooleanField,
  DateTimeField,
  EmailField,
  GoogleAddressField,
  LongTextField,
  NumberField,
  PhoneField,
  RelationshipField,
  RelationshipFieldMany,
  SelectField,
  StringField,
  UniqueField,
} from "../plugins/Cruxtify/field-types";
import { AddressFieldsEnum } from "../plugins/Cruxtify/field-types/AddressField";
import {
  BooleanProperty,
  DateTimeProperty,
  EmailProperty,
  NumberProperty,
  PhoneProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { Account } from "./Account";
import { Contract } from "./Contract";

export class Contact extends CrudModel {
  protected static _typeLabel = "Contact";
  protected static asProperty = "contact";
  public static api = {
    path: "contacts",
  };
  protected static routeBase = "contacts";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );
  protected userIdField = ["user"];

  public get label() {
    return (
      this.getField("first_name")?.stringValue +
      " " +
      this.getField("last_name")?.stringValue
    );
  }

  public setLabel(newVal) {
    const nameParts = newVal.split(" ");
    const fName = nameParts.shift();
    const lName = nameParts.join(" ");
    this.getField("first_name")?.set(fName);
    this.getField("last_name")?.set(lName);
  }

  protected static propertyDefinitions = [
    {
      type: StringProperty,
      opts: {
        name: "first_name",
        label: "First Name",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "last_name",
        label: "Last Name",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "billing_address",
        label: "Street",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "billing_city",
        label: "City",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "billing_state",
        label: "State",
        maxLength: 2,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "billing_zip",
        label: "Zip",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "company",
        label: "Company",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "billing_subdivision",
        label: "Subdivision",
      },
    },

    {
      type: StringProperty,
      opts: {
        name: "country",
        label: "Country",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: PhoneProperty,
      opts: {
        name: "phone",
        label: "Phone",
        rules: ["required"],
      },
    },
    {
      type: PhoneProperty,
      opts: {
        name: "other_phone",
        label: "Other Phone",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "fax",
        label: "Fax",
      },
    },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Email",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "other",
        label: "Other",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "text_notifications",
        label: "Enable Text Notifications",
        description:
          "This will send a text message to the number in the Phone field.",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "email_notifications",
        label: "Enable Email Notifications",
        default: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "latitude",
        label: "Latitude",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "longitude",
        label: "Longitude",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "how_did_they_find_us",
        label: "How did they find us?",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
        rules: ["required"],
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "calls",
        label: "Calls",
        relatedModel: "Call",
        foreignProperty: "contact",
        tableFields: ["call_time", "description", "please_call", "users"],
        userPermissions: UserPermission.Edit,
        newModelDefaults: (contact) => {
          if (contact.isNew) return undefined;
          return {
            users: [contact.getProperty("user").typedValue],
          };
        },
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "contracts",
        label: "Contracts",
        relatedModel: "Contract",
        foreignProperty: "contact",
        tableFields: [
          "id",
          "stage",
          "type",
          "start_date",
          "finish_date",
          "estimate",
          "user",
        ],
        userPermissions: UserPermission.Edit,
        newModelDefaults: (contact) => {
          return {
            user: contact.getProperty("user").value,
            address: contact.getProperty("billing_address").value,
            city: contact.getProperty("billing_city").value,
            state: contact.getProperty("billing_state").value,
            zip: contact.getProperty("billing_zip").value,
            latitude: contact.getProperty("latitude").value,
            longitude: contact.getProperty("longitude").value,
          };
        },
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "comments",
        label: "Comments",
        relatedModel: "Comment",
        foreignProperty: "contact",
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "attachments",
        label: "Files",
        relatedModel: "Attachment",
        foreignProperty: "contact",
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notifications",
        label: "Notifications",
        relatedModel: "Notification",
        foreignProperty: "contact",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "events",
        label: "Events",
        relatedModel: "Event",
        foreignProperty: "contact",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "account",
        label: "Account",
        relatedModel: "Account",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          // {
          //   [UserRole.SuperAdmin]: UserPermission.Edit
          // },
          UserPermission.Read
        ),
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owner",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
        rules: ["required"],
        sortable: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: UniqueField,
      opts: {
        uniqueType: "Name",
        seamless: true,
        id: "name",
        md: 12,
        label: "Name",
        viewComponentName: "view-read-only",
        subFields: [
          { type: StringField, opts: { property: "first_name", md: 6 } },
          { type: StringField, opts: { property: "last_name", md: 6 } },
        ],
      },
    },
    {
      type: UniqueField,
      opts: {
        id: "unique_address",
        label: "Address",
        md: 12,
        apiMethodName: "getUniqueAddress",
        seamless: true,
        afterLabelComponents: ["google-link-button"],
        subFields: [
          {
            type: GoogleAddressField,
            opts: {
              id: "address_group",
              label: "Address",
              md: 12,
              seamless: true,
              disabledSubFields: [
                AddressFieldsEnum.STREET_2,
                AddressFieldsEnum.DIVISION_2,
                AddressFieldsEnum.COUNTRY,
              ],
              subFieldOpts: {
                [AddressFieldsEnum.STREET_1]: {
                  property: "billing_address",
                  id: "billing_address",
                  cols: 12,
                },
                [AddressFieldsEnum.CITY]: {
                  property: "billing_city",
                  id: "billing_city",
                  cols: 6,
                },
                [AddressFieldsEnum.DIVISION_1]: {
                  property: "billing_state",
                  id: "billing_state",
                  cols: 3,
                },
                [AddressFieldsEnum.POSTAL_CODE]: {
                  property: "billing_zip",
                  id: "billing_zip",
                  cols: 3,
                },
                [AddressFieldsEnum.LATITUDE]: {
                  property: "latitude",
                  id: "latitude",
                },
                [AddressFieldsEnum.LONGITUDE]: {
                  property: "longitude",
                  id: "longitude",
                },
              },
            },
          },
        ],
      },
    },
    { type: StringField, opts: { property: "company" } },
    { type: StringField, opts: { property: "billing_subdivision" } },

    {
      type: UniqueField,
      opts: {
        id: "unique_phone",
        label: "Phone",
        seamless: true,
        subFields: [
          {
            type: PhoneField,
            opts: { property: "phone", md: 6, rules: ["usPhone"] },
          },
        ],
      },
    },
    {
      type: UniqueField,
      opts: {
        id: "unique_other_phone",
        label: "Other Phone",
        seamless: true,
        subFields: [
          {
            type: PhoneField,
            opts: { property: "other_phone", md: 6, rules: ["usPhone"] },
          },
        ],
      },
    },
    { type: StringField, opts: { property: "fax", md: 6 } },
    { type: EmailField, opts: { property: "email", md: 6 } },
    { type: StringField, opts: { property: "other", md: 6 } },
    { type: BooleanField, opts: { property: "text_notifications", md: 6 } },
    { type: BooleanField, opts: { property: "email_notifications", md: 6 } },
    {
      type: LongTextField,
      opts: { property: "description", fieldWidth: "full" },
    },
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    {
      type: SelectField,
      opts: {
        property: "how_did_they_find_us",
        options: [
          "Phone book",
          "Referral",
          "Repeat Customer",
          "Yard sign",
          "Truck fleet",
          "TV/Radio",
          "Drove by office",
          "Internet",
          "Transfer Warranty",
          "Other",
        ],
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "calls",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "contracts",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "events",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "comments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "attachments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    { type: RelationshipField, opts: { property: "account", md: 6 } },
    { type: RelationshipField, opts: { property: "last_mod_author" } },
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "user", md: 6 } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "created_at",
        "first_name",
        "last_name",
        "phone",
        "billing_city",
        "billing_state",
        "account",
        "user",
      ],
      useModal: false,
      filters: [new AddressFilter({ name: "address" })],
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "first_name",
          "last_name",

          "billing_address",
          "billing_city",
          "billing_zip",
          "billing_state",

          "phone",
          "other_phone",
          "fax",
          "email",
          "other",

          "name",
          "account",
          "user",

          "created_at",
          "updated_at",
          "last_mod_author",
          "author",

          "description",
          "how_did_they_find_us",
        ],
      },
      enableSearch: true,
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "default",
        navigateAfterSave(this: Contact) {
          const isNew = this.getProperty("created_at").isEmpty;

          const hasNoCalls = this.getProperty("calls").isEmpty;

          if (isNew && hasNoCalls) {
            return {
              path: "/calls/new",
              query: {
                contact: this.getProperty("id").value,
                users: this.getProperty("user").value,
              },
            };
          }

          return false;
        },
        tabs: [
          {
            id: "detailsTab",
            label: "Details",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "nameaddress",
                    fields: ["name", "account", "user", "unique_address"],
                    md: 6,
                  },
                  {
                    id: "contact",
                    fields: [
                      "unique_phone",
                      "unique_other_phone",
                      "fax",
                      "email",
                      "other",
                      "text_notifications",
                      "email_notifications",
                    ],
                    md: 6,
                  },
                  {
                    id: "desc",
                    fields: ["description", "how_did_they_find_us"],
                    md: 6,
                  },
                  {
                    id: "meta",
                    fields: [
                      "id",
                      "legacy_id",
                      "created_at",
                      "updated_at",
                      "last_mod_author",
                      "author",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "callsTab",
            label: "Calls",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "relationships",
                    fields: ["calls"],
                  },
                ],
              },
            },
          },
          {
            id: "contractsTab",
            label: "Contracts",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "relationships",
                    fields: ["contracts"],
                  },
                ],
              },
            },
          },
          {
            id: "eventsTab",
            label: "Appointments",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "relationships",
                    fields: ["events"],
                  },
                ],
              },
            },
          },
          {
            id: "commentsTab",
            label: "Comments & Files",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "relationships",
                    fields: ["comments", "attachments"],
                  },
                ],
              },
            },
          },
          {
            id: "notificationsTab",
            label: "Notifications",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "notifications",
                    fields: [
                      "text_notifications",
                      "email_notifications",
                      "notifications",
                    ],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];

  public static getUniqueAddress(query = {}) {
    return Contract.getUniqueAddress(query);
  }

  public static getUniquePhone(query: any = {}) {
    if (!query.phone) {
      if (query.other_phone) {
        query.phone = query.other_phone;
      } else {
        return { error: "No phone number provided" };
      }
    }

    return this.$nuxt.$api.GET("unique-phone", query).then((res) => {
      if (res.data.error) return { error: res.data.error };

      const dataHydrated = [
        ...res.data.contacts.map((data) => new Contact(data)),
        ...res.data.accounts.map((data) => new Account(data)),
      ];

      return {
        dataHydrated,
      };
    });
  }
}

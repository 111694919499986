import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateField,
  DateTimeField,
  MoneyField,
  NumberField,
  RelationshipField,
  SelectField,
} from "../plugins/Cruxtify/field-types";
import {
  DateProperty,
  DateTimeProperty,
  MoneyProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class Payment extends CrudModel {
  protected static _typeLabel = "Payment";
  protected static asProperty = "payment";
  public static api = {
    path: "payments",
  };
  protected static routeBase = "payments";
  protected userIdField = [];
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        label: "Contact",
        relatedModel: "Contact",
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "invoice",
        label: "Invoice",
        relatedModel: "Invoice",
        sortable: true,
      },
    },
    {
      type: DateProperty,
      opts: {
        name: "date",
        label: "Payment Date",
        rules: ["required"],
        default: DateProperty.now,
      },
    },
    {
      type: MoneyProperty,
      opts: {
        name: "total",
        label: "Payment Total",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "type",
        label: "Payment Type",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: RelationshipField, opts: { property: "contact" } },
    { type: RelationshipField, opts: { property: "invoice" } },
    { type: DateField, opts: { property: "date" } },
    { type: MoneyField, opts: { property: "total" } },
    {
      type: SelectField,
      opts: {
        property: "type",
        options: ["Check", "Credit Card", "Cash", "ACH"],
      },
    },
    { type: RelationshipField, opts: { property: "last_mod_author" } },
    { type: RelationshipField, opts: { property: "author" } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "created_at",
        "contact",
        "invoice.contract.id",
        "invoice",
        "date",
        "total",
      ],
      filterFields: ["invoice", "contact"],
      useModal: true,
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },

    {
      id: "asRelationship",
      columnFields: ["total", "date", "contact"],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["total", "type", "date", "contact", "invoice"],
        },
        {
          id: "1",
          fields: ["author", "last_mod_author"],
        },
      ],
    },
  ];
}

import dayjs from "dayjs";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { CrudProperty } from "../plugins/Cruxtify/CrudProperty";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Event extends CrudModel {
  protected static _typeLabel = "Appointment";
  protected static asProperty = "event";
  public static api = {
    path: "events",
  };
  protected static routeBase = "events";
  protected userIdField = ["user"];
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "zoho_id",
        label: "Zoho ID",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "zoho_user_id",
        label: "Zoho User ID",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "zoho_calendar_id",
        label: "Zoho Calendar ID",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "start_time",
        displayFormat: "dddd M/D/YY h:mm A",
        label: "Start",
        rules: ["required"],
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "end_time",
        label: "End",
        rules: ["required"],

        reactiveValue: {
          value: (prop: CrudProperty) => {
            const startTime = prop.model?.getProperty("start_time");
            if (!startTime || startTime.isEmpty) return undefined;

            // add an hour to start time
            const plusOneHour = dayjs(startTime.typedValue).add(1, "hour");
            return plusOneHour;
          },
        },
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "title",
        label: "Title",
        isPrimaryLabelField: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "location",
        label: "Location",
        default: function (prop: CrudProperty) {
          if (!prop.isEmpty) return undefined;

          let addressModel;
          const contractProp = (prop.model as Event).getProperty("contract");
          const contactProp = (prop.model as Event).getProperty("contact");

          if (!contractProp.isEmpty) {
            addressModel = contractProp.typedValue;
          } else {
            if (contactProp.isEmpty) return undefined;

            addressModel = contactProp.typedValue;
          }

          return addressModel.getField("unique_address").stringValue;
        },
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Added By",
        relatedModel: "User",
        userPermissions: new UserPermissions(UserPermission.Read),
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        label: "Contact",
        relatedModel: "Contact",
        userPermissions: UserPermission.Edit,
        default: (prop: CrudProperty) => {
          const contract = prop.model?.getProperty("contract").typedValue;
          if (!contract) return null;

          const model = contract.getProperty("contact").typedValue;
          return !model.isNew ? model : null;
        },
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owner",
        relatedModel: "User",
        foreignProperty: "events",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
        default: (prop: CrudProperty) => {
          const contact = prop.model?.getProperty("contact").typedValue;
          if (!contact) return null;

          const model = contact.getProperty("user").typedValue;
          return !model.isNew ? model : null;
        },
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notifications",
        label: "Notifications",
        relatedModel: "Notification",
        foreignProperty: "event",
        isRemotelyComputed: true,
        userPermissions: UserPermission.Read,
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: StringField, opts: { property: "zoho_id" } },
    { type: StringField, opts: { property: "zoho_calendar_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: StringField, opts: { property: "title" } },
    { type: DateTimeField, opts: { property: "start_time" } },
    { type: DateTimeField, opts: { property: "end_time" } },
    {
      type: StringField,
      opts: { property: "location", viewComponentName: "google-link" },
    },
    { type: LongTextField, opts: { property: "description" } },
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "contact" } },
    { type: RelationshipField, opts: { property: "contract" } },
    { type: RelationshipField, opts: { property: "user" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["title", "start_time", "end_time", "location"],
        },
        {
          id: "3",
          md: 12,
          fields: ["description", "user", "contact"],
        },
        {
          id: "4",
          md: 12,
          fields: ["notifications"],
        },
        {
          id: "meta",
          fields: [
            "created_at",
            "updated_at",
            "author",
            "zoho_id",
            "zoho_calendar_id",
          ],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      collectionActionComponents: ["zoho-event-search"],
      columnFields: ["title", "start_time", "location", "user"],
      useModal: true,
      enableSearch: true,
      filterFields: ["user"],
      defaultSort: [
        {
          field: "start_time",
          order: "desc",
        },
      ],
    },
    {
      id: "asRelationship",
      columnFields: ["title", "start_time", "location", "user", "author"],
      useModal: true,
      filterFields: ["user"],
      defaultSort: [
        {
          field: "start_time",
          order: "desc",
        },
      ],
    },
  ];

  public static async getZohoCalendars() {
    return this.$nuxt.$api.GET("zoho/calendars").then((res) => {
      return res;
    });
  }

  public static async getZohoCalendarEvents(calendarId: string) {
    return this.$nuxt.$api
      .GET("zoho/calendar-events/" + calendarId)
      .then((res) => {
        return res;
      });
  }

  public static async searchZoho(calendarId: string, search: string) {
    return this.$nuxt.$api
      .GET("zoho/search", { search, calendar: calendarId })
      .then((res) => {
        return res;
      });
  }
}
